<template>
  <main class="container">
    <div class="header h3 mt-2">
          Menú Translados
        <!-- <button @click="showNewModal=true" class="btn-2 float-end"><i class="fas fa-plus me-2"/> Agregar Nueva</button> -->
    </div>

    <!-- DETAILS -->
    <div class="row mt-4">
        <div class="col-md-4" v-for="item in items" :key="item.id" >
            <div class="gradient m-3 px-3 py-4 text-center d-flex zoom shadow" 
                 style="cursor:pointer" @click="$router.push('transfers/' + item.path)">
                <i class="fas fs-1 fa-file-alt me-4 ms-2"></i>
                <h5 v-html="item.name" class="text-start mt-2"></h5>
            </div>
        </div>
    </div>
  </main>
</template>

<script>
export default {
data(){
    return {
        items:[],
        showNewModal:false,
        newItem:{},
        table: 'regions',
        token: ''
    }
},
async mounted(){
    await this.getData()
},
methods:{
    async getData(){        
        this.items = [
            {name: "Contrato", path:"contract"},
            {name: "Conocimiento", path:"knowledge"},
            {name: "Tarjeta de Responsabilidad", path:"listResponsability"},
        ]            
    },
 }
}
</script>

<style>

</style>